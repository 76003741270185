declare class FoxentryBase {
  [key: string]: any
  constructor(apiKey: string)
}

export default defineNuxtPlugin(() => {
  addScript()

  function addScript() {
    const script = document.createElement('script')
    script.src = 'https://app.foxentry.cz/lib'
    script.async = true
    script.type = 'text/javascript'
    script.onload = initFoxentry
    document.head.appendChild(script)
  }

  function initFoxentry() {
    (window as any).Foxentry = new FoxentryBase('1YrOqjDDSe')
  }
})
