import { default as indexZRmv4rwJvqMeta } from "/usr/local/app/pages/index.vue?macro=true";
import { default as indexbcW1kPi65kMeta } from "/usr/local/app/pages/account/addresses/index.vue?macro=true";
import { default as indexf0kufjSeQTMeta } from "/usr/local/app/pages/account/index.vue?macro=true";
import { default as _91code_93Z5gRwhAl1xMeta } from "/usr/local/app/pages/account/orders/[code].vue?macro=true";
import { default as indexyavSc54tHJMeta } from "/usr/local/app/pages/account/orders/index.vue?macro=true";
import { default as securityGIscjYEtcLMeta } from "/usr/local/app/pages/account/security.vue?macro=true";
import { default as accountkzLoSvJwtmMeta } from "/usr/local/app/pages/account.vue?macro=true";
import { default as approvalRreQXiJSeHMeta } from "/usr/local/app/pages/approval.vue?macro=true";
import { default as change_45emailUR6mw1en5xMeta } from "/usr/local/app/pages/auth/change-email.vue?macro=true";
import { default as loginF3gIq4xjsbMeta } from "/usr/local/app/pages/auth/login.vue?macro=true";
import { default as logoutgfTlJJpOwXMeta } from "/usr/local/app/pages/auth/logout.vue?macro=true";
import { default as new_45passwordHKAqxDljR1Meta } from "/usr/local/app/pages/auth/new-password.vue?macro=true";
import { default as registerD1YI4k4ySdMeta } from "/usr/local/app/pages/auth/register.vue?macro=true";
import { default as reset_45passwordjZT5RCzmJ4Meta } from "/usr/local/app/pages/auth/reset-password.vue?macro=true";
import { default as verify_45emailShAT883FSKMeta } from "/usr/local/app/pages/auth/verify-email.vue?macro=true";
import { default as _91code_93n8bQXXLUreMeta } from "/usr/local/app/pages/checkout/confirmation/[code].vue?macro=true";
import { default as indexS4P2YxqBHcMeta } from "/usr/local/app/pages/checkout/index.vue?macro=true";
import { default as checkoutU7ydjr5EV5Meta } from "/usr/local/app/pages/checkout.vue?macro=true";
import { default as _91slug_939CT60eHGEsMeta } from "/usr/local/app/pages/collections/[id]/[slug].vue?macro=true";
import { default as hot_45saleslR4CtXcJUgMeta } from "/usr/local/app/pages/collections/hot-sales.vue?macro=true";
import { default as new_45arrivalsOthMm8zd5xMeta } from "/usr/local/app/pages/collections/new-arrivals.vue?macro=true";
import { default as contactU3U0nxWLZbMeta } from "/usr/local/app/pages/contact.vue?macro=true";
import { default as _91slug_93Qd7SH03cQCMeta } from "/usr/local/app/pages/pages/[slug].vue?macro=true";
import { default as _91_91slug_93_939lFgEk0npoMeta } from "/usr/local/app/pages/products/[id]/[[slug]].vue?macro=true";
import { default as searchYhxkQS4hULMeta } from "/usr/local/app/pages/search.vue?macro=true";
export default [
  {
    name: indexZRmv4rwJvqMeta?.name ?? "index",
    path: indexZRmv4rwJvqMeta?.path ?? "/",
    meta: indexZRmv4rwJvqMeta || {},
    alias: indexZRmv4rwJvqMeta?.alias || [],
    redirect: indexZRmv4rwJvqMeta?.redirect,
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: accountkzLoSvJwtmMeta?.name ?? undefined,
    path: accountkzLoSvJwtmMeta?.path ?? "/pl/account",
    meta: accountkzLoSvJwtmMeta || {},
    alias: accountkzLoSvJwtmMeta?.alias || [],
    redirect: accountkzLoSvJwtmMeta?.redirect,
    component: () => import("/usr/local/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: indexbcW1kPi65kMeta?.name ?? "account-addresses___pl",
    path: indexbcW1kPi65kMeta?.path ?? "addresses",
    meta: indexbcW1kPi65kMeta || {},
    alias: indexbcW1kPi65kMeta?.alias || [],
    redirect: indexbcW1kPi65kMeta?.redirect,
    component: () => import("/usr/local/app/pages/account/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: indexf0kufjSeQTMeta?.name ?? "account___pl",
    path: indexf0kufjSeQTMeta?.path ?? "",
    meta: indexf0kufjSeQTMeta || {},
    alias: indexf0kufjSeQTMeta?.alias || [],
    redirect: indexf0kufjSeQTMeta?.redirect,
    component: () => import("/usr/local/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93Z5gRwhAl1xMeta?.name ?? "account-orders-code___pl",
    path: _91code_93Z5gRwhAl1xMeta?.path ?? "orders/:code()",
    meta: _91code_93Z5gRwhAl1xMeta || {},
    alias: _91code_93Z5gRwhAl1xMeta?.alias || [],
    redirect: _91code_93Z5gRwhAl1xMeta?.redirect,
    component: () => import("/usr/local/app/pages/account/orders/[code].vue").then(m => m.default || m)
  },
  {
    name: indexyavSc54tHJMeta?.name ?? "account-orders___pl",
    path: indexyavSc54tHJMeta?.path ?? "orders",
    meta: indexyavSc54tHJMeta || {},
    alias: indexyavSc54tHJMeta?.alias || [],
    redirect: indexyavSc54tHJMeta?.redirect,
    component: () => import("/usr/local/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: securityGIscjYEtcLMeta?.name ?? "account-security___pl",
    path: securityGIscjYEtcLMeta?.path ?? "security",
    meta: securityGIscjYEtcLMeta || {},
    alias: securityGIscjYEtcLMeta?.alias || [],
    redirect: securityGIscjYEtcLMeta?.redirect,
    component: () => import("/usr/local/app/pages/account/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: approvalRreQXiJSeHMeta?.name ?? "approval___pl",
    path: approvalRreQXiJSeHMeta?.path ?? "/pl/approval",
    meta: approvalRreQXiJSeHMeta || {},
    alias: approvalRreQXiJSeHMeta?.alias || [],
    redirect: approvalRreQXiJSeHMeta?.redirect,
    component: () => import("/usr/local/app/pages/approval.vue").then(m => m.default || m)
  },
  {
    name: change_45emailUR6mw1en5xMeta?.name ?? "auth-change-email___pl",
    path: change_45emailUR6mw1en5xMeta?.path ?? "/pl/auth/change-email",
    meta: change_45emailUR6mw1en5xMeta || {},
    alias: change_45emailUR6mw1en5xMeta?.alias || [],
    redirect: change_45emailUR6mw1en5xMeta?.redirect,
    component: () => import("/usr/local/app/pages/auth/change-email.vue").then(m => m.default || m)
  },
  {
    name: loginF3gIq4xjsbMeta?.name ?? "auth-login___pl",
    path: loginF3gIq4xjsbMeta?.path ?? "/pl/auth/login",
    meta: loginF3gIq4xjsbMeta || {},
    alias: loginF3gIq4xjsbMeta?.alias || [],
    redirect: loginF3gIq4xjsbMeta?.redirect,
    component: () => import("/usr/local/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logoutgfTlJJpOwXMeta?.name ?? "auth-logout___pl",
    path: logoutgfTlJJpOwXMeta?.path ?? "/pl/auth/logout",
    meta: logoutgfTlJJpOwXMeta || {},
    alias: logoutgfTlJJpOwXMeta?.alias || [],
    redirect: logoutgfTlJJpOwXMeta?.redirect,
    component: () => import("/usr/local/app/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordHKAqxDljR1Meta?.name ?? "auth-new-password___pl",
    path: new_45passwordHKAqxDljR1Meta?.path ?? "/pl/auth/new-password",
    meta: new_45passwordHKAqxDljR1Meta || {},
    alias: new_45passwordHKAqxDljR1Meta?.alias || [],
    redirect: new_45passwordHKAqxDljR1Meta?.redirect,
    component: () => import("/usr/local/app/pages/auth/new-password.vue").then(m => m.default || m)
  },
  {
    name: registerD1YI4k4ySdMeta?.name ?? "auth-register___pl",
    path: registerD1YI4k4ySdMeta?.path ?? "/pl/auth/register",
    meta: registerD1YI4k4ySdMeta || {},
    alias: registerD1YI4k4ySdMeta?.alias || [],
    redirect: registerD1YI4k4ySdMeta?.redirect,
    component: () => import("/usr/local/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordjZT5RCzmJ4Meta?.name ?? "auth-reset-password___pl",
    path: reset_45passwordjZT5RCzmJ4Meta?.path ?? "/pl/auth/reset-password",
    meta: reset_45passwordjZT5RCzmJ4Meta || {},
    alias: reset_45passwordjZT5RCzmJ4Meta?.alias || [],
    redirect: reset_45passwordjZT5RCzmJ4Meta?.redirect,
    component: () => import("/usr/local/app/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailShAT883FSKMeta?.name ?? "auth-verify-email___pl",
    path: verify_45emailShAT883FSKMeta?.path ?? "/pl/auth/verify-email",
    meta: verify_45emailShAT883FSKMeta || {},
    alias: verify_45emailShAT883FSKMeta?.alias || [],
    redirect: verify_45emailShAT883FSKMeta?.redirect,
    component: () => import("/usr/local/app/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: checkoutU7ydjr5EV5Meta?.name ?? undefined,
    path: checkoutU7ydjr5EV5Meta?.path ?? "/pl/checkout",
    meta: checkoutU7ydjr5EV5Meta || {},
    alias: checkoutU7ydjr5EV5Meta?.alias || [],
    redirect: checkoutU7ydjr5EV5Meta?.redirect,
    component: () => import("/usr/local/app/pages/checkout.vue").then(m => m.default || m),
    children: [
  {
    name: _91code_93n8bQXXLUreMeta?.name ?? "checkout-confirmation-code___pl",
    path: _91code_93n8bQXXLUreMeta?.path ?? "confirmation/:code()",
    meta: _91code_93n8bQXXLUreMeta || {},
    alias: _91code_93n8bQXXLUreMeta?.alias || [],
    redirect: _91code_93n8bQXXLUreMeta?.redirect,
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: indexS4P2YxqBHcMeta?.name ?? "checkout___pl",
    path: indexS4P2YxqBHcMeta?.path ?? "",
    meta: indexS4P2YxqBHcMeta || {},
    alias: indexS4P2YxqBHcMeta?.alias || [],
    redirect: indexS4P2YxqBHcMeta?.redirect,
    component: () => import("/usr/local/app/pages/checkout/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_939CT60eHGEsMeta?.name ?? "collections-id-slug___pl",
    path: _91slug_939CT60eHGEsMeta?.path ?? "/pl/collections/:id()/:slug()",
    meta: _91slug_939CT60eHGEsMeta || {},
    alias: _91slug_939CT60eHGEsMeta?.alias || [],
    redirect: _91slug_939CT60eHGEsMeta?.redirect,
    component: () => import("/usr/local/app/pages/collections/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: hot_45saleslR4CtXcJUgMeta?.name ?? "collections-hot-sales___pl",
    path: hot_45saleslR4CtXcJUgMeta?.path ?? "/pl/collections/hot-sales",
    meta: hot_45saleslR4CtXcJUgMeta || {},
    alias: hot_45saleslR4CtXcJUgMeta?.alias || [],
    redirect: hot_45saleslR4CtXcJUgMeta?.redirect,
    component: () => import("/usr/local/app/pages/collections/hot-sales.vue").then(m => m.default || m)
  },
  {
    name: new_45arrivalsOthMm8zd5xMeta?.name ?? "collections-new-arrivals___pl",
    path: new_45arrivalsOthMm8zd5xMeta?.path ?? "/pl/collections/new-arrivals",
    meta: new_45arrivalsOthMm8zd5xMeta || {},
    alias: new_45arrivalsOthMm8zd5xMeta?.alias || [],
    redirect: new_45arrivalsOthMm8zd5xMeta?.redirect,
    component: () => import("/usr/local/app/pages/collections/new-arrivals.vue").then(m => m.default || m)
  },
  {
    name: contactU3U0nxWLZbMeta?.name ?? "contact___pl",
    path: contactU3U0nxWLZbMeta?.path ?? "/pl/contact",
    meta: contactU3U0nxWLZbMeta || {},
    alias: contactU3U0nxWLZbMeta?.alias || [],
    redirect: contactU3U0nxWLZbMeta?.redirect,
    component: () => import("/usr/local/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexZRmv4rwJvqMeta?.name ?? "index___pl",
    path: indexZRmv4rwJvqMeta?.path ?? "/pl",
    meta: indexZRmv4rwJvqMeta || {},
    alias: indexZRmv4rwJvqMeta?.alias || [],
    redirect: indexZRmv4rwJvqMeta?.redirect,
    component: () => import("/usr/local/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qd7SH03cQCMeta?.name ?? "pages-slug___pl",
    path: _91slug_93Qd7SH03cQCMeta?.path ?? "/pl/pages/:slug()",
    meta: _91slug_93Qd7SH03cQCMeta || {},
    alias: _91slug_93Qd7SH03cQCMeta?.alias || [],
    redirect: _91slug_93Qd7SH03cQCMeta?.redirect,
    component: () => import("/usr/local/app/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_939lFgEk0npoMeta?.name ?? "products-id-slug___pl",
    path: _91_91slug_93_939lFgEk0npoMeta?.path ?? "/pl/products/:id()/:slug?",
    meta: _91_91slug_93_939lFgEk0npoMeta || {},
    alias: _91_91slug_93_939lFgEk0npoMeta?.alias || [],
    redirect: _91_91slug_93_939lFgEk0npoMeta?.redirect,
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: searchYhxkQS4hULMeta?.name ?? "search___pl",
    path: searchYhxkQS4hULMeta?.path ?? "/pl/search",
    meta: searchYhxkQS4hULMeta || {},
    alias: searchYhxkQS4hULMeta?.alias || [],
    redirect: searchYhxkQS4hULMeta?.redirect,
    component: () => import("/usr/local/app/pages/search.vue").then(m => m.default || m)
  }
]