import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const runtimeConfig = useRuntimeConfig()

  if (!runtimeConfig.public.sentry.dsn)
    return

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: runtimeConfig.public.sentry.dsn,
    environment: runtimeConfig.public.sentry.environment,
    release: runtimeConfig.public.sentry.release,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: runtimeConfig.public.sentry.tracesSampleRate,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'https://huglo.pl'],

    replaysSessionSampleRate: runtimeConfig.public.sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: runtimeConfig.public.sentry.replaysOnErrorSampleRate,
  })
})
