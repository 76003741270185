import revive_payload_client_0UlsyRvYFY from "/usr/local/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_change_pcdflew6a2e3evvjpgwoxksw74/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vSQMx2mGky from "/usr/local/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_change_pcdflew6a2e3evvjpgwoxksw74/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_N9kOQjVqXA from "/usr/local/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_change_pcdflew6a2e3evvjpgwoxksw74/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_wVi8FwjKr8 from "/usr/local/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_change_pcdflew6a2e3evvjpgwoxksw74/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_tfwdT3kQke from "/usr/local/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_change_pcdflew6a2e3evvjpgwoxksw74/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_xwfCwmUSOc from "/usr/local/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_change_pcdflew6a2e3evvjpgwoxksw74/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import provideState_See1WU7n61 from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.0.0_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_graph_gfgfyhifeo4ixmeqkjyyseu6se/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.mjs";
import plugin_xuy7MCCzJQ from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.17.2/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_client_0es7dvT31Z from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.17.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import i18n_yMGZLvpVDl from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.17.2_vue@3.4.26_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import nuxt_plugin_3Hz0tamRXx from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.3_rollup@4.17.2/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import pwa_icons_DTqMcVku2D from "/usr/local/app/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@4.17.2_vite@5.2.10_@types+node@20.12.7_terser@5.31.0__workbox-bui_pi35cf4q7b52pnx2r4dyseqlre/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_tBpx2yLgZ2 from "/usr/local/app/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@4.17.2_vite@5.2.10_@types+node@20.12.7_terser@5.31.0__workbox-bui_pi35cf4q7b52pnx2r4dyseqlre/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_Pb4VNP6OMp from "/usr/local/app/modules/stripe/runtime/plugin.ts";
import plugin_HMUycbzStU from "/usr/local/app/modules/pino/runtime/plugin.ts";
import plugin_4fNvsrmUFT from "/usr/local/app/modules/image-handler/runtime/plugin.ts";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import plugin_client_n65crEo4Aa from "/usr/local/app/modules/datadog/runtime/plugin.client.ts";
import chunk_reload_client_gBW8UIz9dp from "/usr/local/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_change_pcdflew6a2e3evvjpgwoxksw74/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import sentry_client_shVUlIjFLk from "/usr/local/app/plugins/sentry.client.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  revive_payload_client_0UlsyRvYFY,
  unhead_vSQMx2mGky,
  router_N9kOQjVqXA,
  payload_client_wVi8FwjKr8,
  check_outdated_build_client_tfwdT3kQke,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xwfCwmUSOc,
  provideState_See1WU7n61,
  plugin_xuy7MCCzJQ,
  plugin_client_0es7dvT31Z,
  unocss_MzCDxu9LMj,
  i18n_yMGZLvpVDl,
  nuxt_plugin_3Hz0tamRXx,
  pwa_icons_DTqMcVku2D,
  pwa_client_tBpx2yLgZ2,
  plugin_Pb4VNP6OMp,
  plugin_HMUycbzStU,
  plugin_4fNvsrmUFT,
  plugin_xDtleEcACi,
  plugin_client_n65crEo4Aa,
  chunk_reload_client_gBW8UIz9dp,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  foxentry_client_gcy3vwc779,
  scroll_client_HJUo2UKx9n,
  sentry_client_shVUlIjFLk,
  vee_validate_KcKlKmvCrJ
]